import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

const Login = () => {
  return (
    <div className="register-page-container">
      <div>
        <img
          src={require('../../assets/images/login.jpg')}
          alt="A person using a laptop"
          className="object-cover w-full h-full"
        />
      </div>
      {/* <div>Test</div> */}
      <div className="flex items-center justify-center bg-white">
        <div>
          <div className="text-center">
            <img
              src={require('../../assets/images/logo.png')}
              alt="Company Logo"
              className="mx-auto "
            />
          </div>
          <form>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-zinc-700"
              >
                Email ID
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full px-3 py-2 border border-zinc-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-zinc-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="mt-1 block w-full px-3 py-2 border border-zinc-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
            <div>
              <Button
                type="submit"
              >
                LOG IN
              </Button>
            </div>
          </form>
          <p className="text-xs text-zinc-500">
            This computer network belongs to Amberoon Inc. d/b/a Manatoko BOIR
            and may be used only by Amberoon employees and Customers only for
            work-related purposes. Amberoon Inc reserves the right to monitor
            use of this network to ensure network security and to respond to
            specific allegations of user misuse. Use of this network shall
            constitute consent to monitoring for such purposes. In addition, the
            Amberoon Inc reserves the right to consent to a valid law
            enforcement request to search the network for evidence of a crime
            stored within the network.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
