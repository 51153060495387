import React, { useEffect, useState } from 'react';
import axios from "axios";
import UserService from '../services/UserService.js';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import CreditCard from "./CreditCard";

const serverConfig = require("../config/server.js");

//const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');//test
//const stripePromise = loadStripe("pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3");//test Nevil
const stripePromise = loadStripe("pk_test_51ObPOnSHomqqZI0rj1EtaJZp7CxHQYI25mmOm3n6WHztJa2pZCUIWdKndEx3KGGmwKP8FxVdDNx9Dh7azuZBDpkD004YNpyIpH");//test Ranjith

const demos = [
  {
    methodName: "credit_card_elements",
    label: "Credit Card",
    icon: "credit-card-icon.png",
    component: CreditCard
  },
];

const Payment = ({token, handleLogout, curPageName, applicationId, getGroupData}) => {

  const [currency, setCurrency] = useState(null);

  //const[method, setMethod] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if(currency !== null){
      //fetch(serverConfig.fapi_base_url + "create_payment_intent", {
      fetch(serverConfig.fapi_base_url + 'create_payment_intent', {
        method: "POST",
        body: JSON.stringify({amount:99, currency:currency}),
      }).then(async (result) => {
        var { clientSecret } = await result.json();
        setClientSecret(clientSecret);
      });
    }
  }, [currency]);

  useEffect(() => {
    if(token !== null){
      axios.post(serverConfig.api_base_url + "org_currency_get",{token: token})
      .then(response=>{
        console.log('org_currency_get', response);
        if(response.data.status === 200) {
          setCurrency(response.data.currency);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handlePaymentSet = (subscriptionID, validCoupon) => {
    let formdata = {token:token, entity_group:curPageName, items:{isSubscribed: true, contactEmail:UserService.getUsername(), contactFirstName:UserService.getWelcomeName(), contactLastName:UserService.getFamilyName(), isCouponCodeValid:validCoupon}, application_id:applicationId};
    axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdata)
    .then(response=>{
      console.log('customer_entity_group_details_set response', response);
      if(response.data.status === 200) {
        console.log(currency, subscriptionID);
        axios.post(serverConfig.api_base_url + "payment_status_set",{token: token, application_id:applicationId, currency: currency, subscriptionID: subscriptionID})
        .then(response=>{
          console.log('payment_status_set', response);
          if(response.data.status === 200) {
            getGroupData();
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));

        //getGroupData();

      } else if(response.data.status === 407){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (<>
    {clientSecret && (
      <Elements stripe={stripePromise} options={options}>
        {/* <CreditCard /> */}
        {demos.map(({ methodName, component: Component }, index) => {
          return (
            <div key={index}>
              {(() => {
                switch ("credit_card_elements") {
                  case methodName:
                    return (
                      <Component client_secret={clientSecret} handlePaymentSet={handlePaymentSet} token={token} handleLogout={handleLogout} />
                    );
                  default:
                    return (
                      <div></div>
                    );
                  }
              })()}
            </div>
          )
        })}
      </Elements>
    )}
  </>);
};

export default Payment;