import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import UserService from "../../services/UserService.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Payment from "./Payment.jsx";
import logoSvg from './../../assets/images/logo.svg';

const serverConfig = require("../../config/server.js");

const UserRegistration = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const org_slug = searchParams.get("id");
  console.log(searchParams.get(org_slug));

  const [logoBase64Text, setLogoBase64Text] = useState('');
  const navigate = useNavigate();

  const [isAuthorizedSigner, setIsAuthorizedSigner] = useState(false);
  const [inputs, setInputs] = useState({});
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleRegister = (event) => {
    event.preventDefault();
    console.log(inputs);
    axios.post(serverConfig.api_base_url + "user_signup",{name: inputs.name, email_id: inputs.email_id, org: org_slug})
    .then(response=>{
      console.log('user_signup response', response);
      if(response.data.status === 200) {
        setResponseMessage("Please check your email to proceed with the registration process");
        setInputs({});
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  const [showAuthorizedSignerPopup, setShowAuthorizedSignerPopup] = useState(false);
  const handleShowAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(true);
  }
  const handleCloseAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(false);
    //setInputs({});
  }

  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="login-left p-5 d-flex flex-column justify-content-center">
            {/* <img src={require("../../assets/images/login.jpg")} alt="Login Banner" height={300} width={500} /> */}
            <div className="kc-form-image">
              <img
                src={logoSvg}
                alt="Logo"
                className="img-fluid" width="300"
              />
            </div>
            <h1 style={{fontSize: "18px"}}>Manatoko BOIR Annual Subscription</h1>
            <ul>
              <li>Answer Beneficial Ownership requirements</li>
              <li>File BOI information with FinCEN</li>
              <li>Manage and update your data</li>
            </ul>
          </div>
          <div className="form-container pt-4" style={{maxWidth: "none", width:"600px"}}>
            <div className="signup-form-wrapper">
              <div className="kc-content">
                <div className="kc-content-wrapper">
                  <div className="kc-form">
                    {/* <div className="kc-form-image">
                      <img
                        src={logoBase64Text}
                        alt="Logo"
                        className="img-fluid"
                      />
                    </div> */}
                    <div>
                      <h1 style={{fontSize: "18px"}}>Company Applicant / Authorized Signer Registration</h1>
                      <p><span className="txt-red">*</span>An authorized signer for Manatoko BOIR refers to the individual who is responsible for submitting the BOI report on behalf of the company. This person is someone with authority to act for the company, such as a company officer or authorized representative.</p>
                      <Button variant="primary" className="signup-submit-btn" type="button" onClick={handleShowAuthorizedSignerPopup}>Register</Button>
                      <div class='redirect-signup'>
                        <div class='redirect-text'>Already Registered?</div> <button onClick={()=>{ navigate("/customer")} } class='login-btn'>LOG IN</button>
                      </div>
                      <Modal show={showAuthorizedSignerPopup} onHide={handleCloseAuthorizedSignerPopup} centered keyboard={false}>
                        <Modal.Header closeButton>
                          <Modal.Title>Authorized Signer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Label>Are you the authorized signer for your business? <span className="txt-red">*</span></Form.Label>
                          <div className="d-flex mb-1">
                            <Form.Check inline type="radio" name="isAuthorizedSigner" value={"Yes" || ""} onChange={(e) => {setInputs({...inputs, isAuthorizedSigner: e.target.value}); setIsAuthorizedSigner(true)}} id="isAuthorizedSignerYes" label="Yes" required defaultChecked={inputs.isAuthorizedSigner === "Yes"} />
                            <Form.Check inline type="radio" name="isAuthorizedSigner" value={"No" || ""} onChange={(e) => {setInputs({...inputs, isAuthorizedSigner: e.target.value}); setIsAuthorizedSigner(false)}} id="isAuthorizedSignerNo" label="No" required defaultChecked={inputs.isAuthorizedSigner === "No"} />
                          </div>
                          {inputs.isAuthorizedSigner === "Yes" && <span className="txt-green">Please close this popup and proceed with the payment.</span>}
                          {inputs.isAuthorizedSigner === "No" && <span className="txt-red">Only the authorized user can fill this form. Please forward the invite email you received to the authorized user.</span>}
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>
                    </div>
                    <Payment orgSlug={org_slug} isAuthorizedSigner={isAuthorizedSigner} setIsAuthorizedSigner={setIsAuthorizedSigner} />
                    {/* <Form onSubmit={handleRegister}>
                      <div className="flex flex-col">
                        <Form.Group>
                          <Form.Control
                            name="name"
                            className="signup-input"
                            type="text"
                            placeholder="Enter your name"
                            value={inputs.name || ""}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="flex flex-col mt-3">
                        <Form.Group>
                          <Form.Control
                            name="email_id"
                            className="signup-input"
                            type="email"
                            placeholder="Enter your email"
                            value={inputs.email_id || ""}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="kc-form-buttons mt-4 mb-4">
                        <Button
                          variant="primary"
                          className="signup-submit-btn"
                          type="submit"
                        >
                          Register
                        </Button>
                      </div>
                      <div class='redirect-signup'>
                                <div class='redirect-text'>Already Registered?</div> <button onClick={()=>{ navigate("/customer")} } class='login-btn'>LOG IN</button>
                                </div>
                      <p className="txt-green">{responseMessage ? responseMessage : <>&nbsp;</>}</p>
                    </Form> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="disclaimer">
            This computer network belongs to Amberoon Inc. and may be used only by Amberoon employees and Customers only for work-related purposes. Amberoon Inc reserves the right to monitor use of this network to ensure network security and to respond to specific allegations of user misuse. Use of this network shall constitute consent to monitoring for such purposes. In addition, the Amberoon Inc reserves the right to consent to a valid law enforcement request to search the network for evidence of a crime stored within the network.
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRegistration;
