import React, { useEffect, useState } from 'react';
import axios from "axios";

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import CreditCard from "./CreditCard";

const serverConfig = require("../../config/server.js");
const appConfig = require("../../config/config.js");

const stripePromise = loadStripe(appConfig.stripe_key);


const demos = [
  {
    methodName: "credit_card_elements",
    label: "Credit Card",
    icon: "credit-card-icon.png",
    component: CreditCard
  },
];

const Payment = ({orgSlug, isAuthorizedSigner, setIsAuthorizedSigner}) => {

  const [currency, setCurrency] = useState(null);
  const [amount, setAmount] = useState(null);

  //const[method, setMethod] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if(currency !== null && amount !== null){
      //fetch(serverConfig.fapi_base_url + "create_payment_intent", {
      fetch(serverConfig.fapi_base_url + 'create_payment_intent', {
        method: "POST",
        body: JSON.stringify({amount:amount, currency:currency}),
      }).then(async (result) => {
        var { clientSecret } = await result.json();
        setClientSecret(clientSecret);
      });
    }
  }, [currency, amount]);

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "org_currency_get", {org: orgSlug})
    .then(response=>{
      console.log('org_currency_get', response);
      if(response.data.status === 200) {
        setCurrency(response.data.currency);
        setAmount(response.data.amount);
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  },[]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const [responseMessage, setResponseMessage] = useState("");

  const handlePaymentSet = (subscriptionID, validCoupon, amountAfterDiscount, couponCode, name, email) => {
    console.log(currency, subscriptionID);
    axios.post(serverConfig.api_base_url + "payment_status_set",{currency: currency, subscriptionID: subscriptionID, isCouponCodeValid:validCoupon, amountAfterDiscount: amountAfterDiscount, couponCode: couponCode, name: name, email: email})
    .then(response=>{
      console.log('payment_status_set', response);
      if(response.data.status === 200) {
        //register
        axios.post(serverConfig.api_base_url + "user_signup",{name: name, email_id: email, org: orgSlug})
        .then(response=>{
          console.log('user_signup response', response);
          if(response.data.status === 200) {
            setResponseMessage("Please check your email to continue");
            setIsAuthorizedSigner(false);
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
        
      } else if(response.data.status === 301){
        //handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (<>
    {clientSecret && (
      <Elements stripe={stripePromise} options={options}>
        {/* <CreditCard /> */}
        {demos.map(({ methodName, component: Component }, index) => {
          return (
            <div key={index}>
              {(() => {
                switch ("credit_card_elements") {
                  case methodName:
                    return (
                      <Component client_secret={clientSecret} handlePaymentSet={handlePaymentSet} amount={amount} isAuthorizedSigner={isAuthorizedSigner} responseMessage={responseMessage} />
                    );
                  default:
                    return (
                      <div></div>
                    );
                  }
              })()}
            </div>
          )
        })}
      </Elements>
    )}
  </>);
};

export default Payment;