import React, { useState, useEffect } from "react";
import { Container, Navbar, Button, Modal } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import UserService from '../services/UserService';
import Header from '../components/Header';
import FormSubmit from '../components/FormSubmit';

const serverConfig = require("../config/server.js");

const Report = () => {

  const navigate = useNavigate();

  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  const [privilegeAppListRead, setPrivilegeAppListRead] = useState(false);

  const[pageContent, setPageContent] = useState(1);
  const[applicationId, setApplicationId] = useState(null);

  const [token, setToken] = useState(null);
  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        //handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [curPageName, setCurPageName] = useState(null);
  const [currentEntityGroupName, setCurrentEntityGroupName] = useState(null);
  const [step, setstep] = useState(1);
  const [refid, setRefid] = useState("");
  const [hpwd, setHpwd] = useState("");
  const [emailid, setEmailid] = useState("");
  const [steps, setSteps] = useState([]);
  const[stateUSLookup, setStateUSLookup] = useState([]);
  const [companyName, setCompanyName] = useState("E. GIL DENTAL, CORP.");
  const [companyJurisdictionCode, setCompanyJurisdictionCode] = useState("us_fl");
  const [openCorpData, setOpenCorpData] = useState({});

  /* const nextStep = (current_page) => {
    console.log(current_page, steps, step, steps[step].group);
    setstep(step + 1);
    if(current_page) {
      setCurPageName(current_page);
      console.log(current_page);
      console.log(steps.findIndex((item) => item.group === current_page));
      setstep((steps.findIndex((item) => item.group === current_page)) + 2);
    } else {
      console.log(current_page, steps, step, steps[step].group);
      console.log(steps.slice(step).filter(el => el.is_disabled === 0)[0]);
      //setCurPageName(steps[step].group);
      setCurPageName(steps.slice(step).filter(el => el.is_disabled === 0)[0].group);
      console.log((steps.findIndex((item) => item.group === steps.slice(step).filter(el => el.is_disabled === 0)[0].group)) + 1);
      setstep((steps.findIndex((item) => item.group === steps.slice(step).filter(el => el.is_disabled === 0)[0].group)) + 1);
    }
  }; */

  const nextStep = () => {
    console.log(steps, step);
    console.log(steps.slice(step), steps.slice(step).find((el) => el.is_disabled === 0).group, steps.slice(step).findIndex((el) => el.is_disabled === 0));
    setCurPageName(steps.slice(step).find((el) => el.is_disabled === 0).group);
    setstep((steps.findIndex((item) => item.group === steps.slice(step).find((el) => el.is_disabled === 0).group)) + 1);
  };

  const prevStep = () => {
    console.log(steps, step);
    setCurPageName(steps.slice(0, step-1).findLast((el) => el.is_disabled === 0).group);
    //setstep(steps.slice(0, step-1).findLastIndex((el) => el.is_disabled === 0) + 1);
    setstep((steps.findIndex((item) => item.group === steps.slice(0, step-1).findLast((el) => el.is_disabled === 0).group)) + 1);
  };

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  const customerGroupGet = (current_page) => {
    axios.post(serverConfig.api_base_url + "customer_entity_group_get",{token: token}).then(response=>{
      console.log('customer_entity_group_get response', response);
      if(response.data.status === 200) {
        if(response.data.items !== null){
          let res = response.data.items;
          res.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          console.log(res);
          setSteps(res);
          //setCurPageName(res[0].group);
          // setCurPageName('documentation');
          // setstep(2);
          console.log(currentEntityGroupName);
          if(current_page){
            setCurPageName(current_page);
            console.log(res.findIndex((item) => item.group === current_page) + 1);
            setstep(res.findIndex((item) => item.group === current_page) + 1);
          } else if(currentEntityGroupName){
            setCurPageName(currentEntityGroupName);
            console.log(res.findIndex((item) => item.group === currentEntityGroupName) + 1);
            setstep(res.findIndex((item) => item.group === currentEntityGroupName) + 1);
          } else {
            setCurPageName(res[0].group);
          }
          //setCurPageName('filing_information');
          //setstep(1);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    const script = document.createElement('script');
    script.src = "https://tfaisuper.blob.core.windows.net/$web/supervity-chat-widget.js?token=ff64b6ec7a532383318e01f9&orgId=1697&collection=1697_Fincet_FAQS&chatWidgetWidth=600&chatFontSize=14px&accentColor=%23fdc600&brandColor=%23fdc600&botChatBgColor=%23eaf0f6&botFontColor=%23000000&brandIcon=https://i.ibb.co/9HYnZL4/logo-1.png&chatWidgetCloseIconColor=%231d262e&headerMsg1Color=%23000000&headerMsg2Color=%23000000&defaultOpen=true&chatWidgetWidth=90vw&chatWidgetMinHeight=55dvh";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    console.log(token);
    console.log(sessionStorage.getItem('token'));
    //setToken(sessionStorage.getItem('token'));

    if(token !== null && token !== undefined){
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
            navigate("/customer");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      //application_list_read - is admin / banker
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "application_list_read"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeAppListRead(true);
            navigate("/applications");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeAppListRead(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"state_us"})
      .then(response=>{
        console.log(response);
        if(response.data.data !== null){
          setStateUSLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, sessionStorage.getItem('token')]);

  useEffect(() => {
    if(privilegeSubmitData){
      axios.post(serverConfig.api_base_url + "customer_application_list_get",{token: token, application_self: 1}).then(response=>{
        console.log('customer_application_list_get response', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            console.log(response.data.items.filter(el => el.status === "Open"))
            setApplicationId(response.data.items.filter(el => el.status === "Open")[0].application_id);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      customerGroupGet();
    }
  }, [privilegeSubmitData]);

  useEffect(() => {
    if(applicationId !== null && applicationId !== undefined && applicationId !== ''){
      axios.post(serverConfig.api_base_url + "customer_current_entity_group_get",{token: token, application_id: applicationId}).then(response=>{
        console.log('customer_current_entity_group_get response', response);
        if(response.data.status === 200) {
          if(response.data.page_name !== null && response.data.page_name !== undefined){
            setCurrentEntityGroupName(response.data.page_name);
          } else {
            setCurrentEntityGroupName(null);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    axios.post(serverConfig.api_base_url + "customer_entity_group_details_get",{token:token, entity_group:"reporting_company_information", application_id:applicationId})
    .then(response=>{
      console.log('customer_entity_group_details_get', response);
      console.log(response.data.items);
      if(response.data.items !== null){
        let res = response.data.items;
        console.log(res);
        console.log(res.group_sections);
        res.group_sections.sort(function(a,b){
          return a.section_seq_no - b.section_seq_no;
        });
        
        let lnan = res.group_sections.filter(section => section.section_name === "legal_name_alternate_name");
        if(lnan.length > 0){
          let open_corp = lnan[0].section_entities.filter(entities => entities.entity_name === "openCorporateDataCompany");
          console.log(JSON.parse(open_corp[0].entity_data));
          if(open_corp[0].entity_data === null || open_corp[0].entity_data === ''){//call open corporates only if not called earlier, otherwise it will overwrite the previous open corporate updated data
            //open corporates
            var searchPattern = new RegExp('^' + 'us_');
            if (searchPattern.test(companyJurisdictionCode)) {//pre populate only if US
              axios.get(serverConfig.fapi_opencorp_url + "fetch_company", {
                params: {
                  company_name: companyName,
                  company_jurisdiction: companyJurisdictionCode
                }
              })
              // Handle the response from backend here
              .then((res) => {
                console.log(res);
                
                let companyData = res.data.opencorp_data;
                if(companyData !== null){
                  let addr = companyData.data?.most_recent?.filter(el => el?.datum?.title === "Head Office Address")[0].datum?.description;
                  let formdataReportingCompany = {token:token, entity_group:'reporting_company_information', application_id:applicationId, items:{
                    reportingCompanyLegalName: companyData.name,
                    alternateNames: companyData.alternative_names,
                    openCorporateDataCompany: companyData,
                    openCorporateDataOfficer: companyData.officers,
                    openCorporateDataCompanyCurrentStatus: companyData.current_status,
                    reportingCompanyTaxIdentificationType: "EIN",
                    reportingCompanyTaxIdentificationNumber: companyData.identifiers?.filter(el => el.identifier.identifier_system_code === 'us_fein')[0].identifier.uid,
                    countryUS: addr ? "United States" : undefined,
                    countryUSCode: addr ? "US" : undefined,
                    addressUS: addr?.split(', ')[0]+ (addr?.split(', ').length > 4 ? (', '+addr?.split(', ')[1]) : ""),
                    stateUS: stateUSLookup.filter(el => el.state_code === addr?.split(', ')[addr?.split(', ').length - 2])[0]?.value,
                    stateUSCode: addr?.split(', ')[addr?.split(', ').length - 2],
                    cityUS: addr?.split(', ')[addr?.split(', ').length - 3],
                    zipcodeUS: addr?.split(', ')[addr?.split(', ').length - 1],
                    //addressUSPlaceId: ""
                  }};
                  console.log(formdataReportingCompany);

                  axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdataReportingCompany)
                  .then(response=>{
                    console.log('customer_entity_group_details_set response', response);
                    if(response.data.status === 200) {
                      console.log('success');
                    } else if(response.data.status === 407){
                      handleLogout();
                    } else {
                      alert(response.data.message);
                    }
                  })
                  .catch(err=>console.log("error is",err));

                  var fullName = companyData.agent_name.split(' '),
                      firstName = fullName[0],
                      middleName = fullName.length > 2 ? fullName[fullName.length - 2] : undefined,
                      lastName = fullName[fullName.length - 1];
                  let formdataCompanyApplicant = {token:token, entity_group:'company_applicant_information', application_id:applicationId, items:{
                    applicantIndividualLastName: lastName,
                    applicantFirstName: firstName,
                    applicantMiddleName: middleName,
                    applicantCurrentCountryJurisdiction: companyData.agent_address?.street_address ? "United States" : undefined,
                    applicantCurrentCountryJurisdictionCode: companyData.agent_address?.street_address ? "US" : undefined,
                    applicantCurrentAddress: companyData.agent_address?.street_address,
                    applicantCurrentState: stateUSLookup.filter(el => el.state_code === companyData.agent_address?.region)[0].value,
                    applicantCurrentStateCode: companyData.agent_address?.region,
                    applicantCurrentCity: companyData.agent_address?.locality,
                    applicantCurrentZipcode: companyData.agent_address?.postal_code,
                    //applicantCurrentAddressPlaceId: ""
                  }};
                  console.log(formdataCompanyApplicant);

                  axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdataCompanyApplicant)
                  .then(response=>{
                    console.log('customer_entity_group_details_set response', response);
                    if(response.data.status === 200) {
                      console.log('success');
                    } else if(response.data.status === 407){
                      handleLogout();
                    } else {
                      alert(response.data.message);
                    }
                  })
                  .catch(err=>console.log("error is",err));

                  const unique = companyData.officers.length > 0 && companyData.officers.filter((obj, index) => {
                    return index === companyData.officers.findIndex(o => obj.name === o.name);
                  });
                  console.log(unique);
                  let boArr = unique.length > 0 && unique.map((item) => {
                    var boFullName = item.officer.name.split(' '),
                      boFirstName = boFullName[0],
                      boMiddleName = boFullName.length > 2 ? boFullName[boFullName.length - 2] : undefined,
                      boLastName = boFullName[boFullName.length - 1];
                    return({
                    "boSSN": "",
                    "boSuffix": "",
                    "boFincenId": "",
                    "boFirstName": boFirstName,
                    "boMiddleName": boMiddleName,
                    "boDateOfBirth": item.officer.date_of_birth,
                    "isExemptEntity": "",
                    "boDocumentIdType": "",
                    "boResidentialCity": item.officer.address?.split(', ')[item.officer.address?.split(', ').length - 3],
                    "boDocumentIdNumber": "",
                    "boResidentialState": stateUSLookup.filter(el => el.state_code === item.officer.address?.split(', ')[item.officer.address?.split(', ').length - 2])[0].value,
                    "boResidentialAddress": item.officer.address?.split(', ')[0]+ (item.officer.address?.split(', ').length > 4 ? (', '+item.officer.address?.split(', ')[1]) : ""),
                    "boResidentialZipcode": item.officer.address?.split(', ')[item.officer.address?.split(', ').length - 1],
                    "boResidentialStateCode": item.officer.address?.split(', ')[item.officer.address?.split(', ').length - 2],
                    "boDocumentIdIssuingState": "",
                    "boIdentifyingDocumentImage": "",
                    "isParentGuardianInformation": "",
                    "boDocumentIdIssuingStateCode": "",
                    "boDocumentIdIssuingLocalTribal": "",
                    "boIdentifyingDocumentImageBase64": "",
                    "boResidentialCountryJurisdiction": item.officer.address ? "United States" : undefined,
                    "boDocumentIdIssuingLocalOtherTribe": "",
                    "boDocumentIdIssuingLocalTribalCode": "",
                    "boIndividualLastNameEntityLegalName": boLastName,
                    "boResidentialCountryJurisdictionCode": item.officer.address ? "US" : undefined,
                    "boDocumentIdIssuingCountryJurisdiction": "",
                    "boDocumentIdIssuingCountryJurisdictionCode": ""
                  })});
                  console.log(boArr);

                  let formdataBeneficialOwner = {token:token, entity_group:'beneficial_owner_information', application_id:applicationId, items:{
                    "boInformation": boArr
                  }};
                  console.log(formdataBeneficialOwner);

                  axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdataBeneficialOwner)
                  .then(response=>{
                    console.log('customer_entity_group_details_set response', response);
                    if(response.data.status === 200) {
                      console.log('success');
                    } else if(response.data.status === 407){
                      handleLogout();
                    } else {
                      alert(response.data.message);
                    }
                  })
                  .catch(err=>console.log("error is",err));
                }
              })
              // Catch errors if any
              .catch((err) => {
                console.log("error is",err);
              });
            }
          } else {
            console.log('Open corporates already called');
          }
        }
      }
    })
    .catch(err=>console.log("error is",err));
  }, [applicationId]);

  useEffect(() => {
    if(token !== null && token !== undefined){
      customerGroupGet();
    }
  }, [currentEntityGroupName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [curPageName]);

  return (
    <div className="body-wrap bg-color8 pb-3">
      <Header isLoggedIn={(token !== null && token !== undefined && token !== '') ? true : false} />
      {privilegeSubmitData && <>
        {pageContent === 1 && <>
          <main>
            <Container fluid>
              <div className="mb-4 pb-1 border-bottom">
                <h1 className="mb-3">Beneficial Ownership Information Report (BOIR)</h1>
                <p className="fw-500">OMB No. 1506-0076</p>
                <p className="fw-500">Complete the report in its entirety with all required information. Fields marked with an asterisk (<span className="txt-red">*</span>) symbol are the fields that, at a minimum, must be completed by all companies to file the report.</p>
                <p className="fw-500">Reporting companies, however, must include all information 31 CFR 1010.380(b) requires them to provide, regardless of whether a field is marked with an asterisk (<span className="txt-red">*</span>) symbol. {/* These information requirements are summarized above under HELP. */}</p>
              </div>
              <h5>Documents you will need:</h5>
              <ol className="ps-3">
                <li><b>Your passport / driver license</b></li>
                <li><b>Supporting documentation</b><br/>(ie. driver license, government ID, bank statement).</li>
              </ol>
              {/* <div className="info-box mt-4">
                <span className="info-icon"></span>
                <span>You will need just 10 minutes to fill out this form</span>
              </div> */}
            </Container>
          </main>
          <footer className="mt-auto">
            <Container fluid>
              <div className="mt-auto"><Button variant="primary" className="w-100" onClick={() => setPageContent(2)}>Got it</Button></div>
            </Container>
          </footer>
        </>}
        {pageContent === 2 && <>
          <main>
            {(token && curPageName && steps.length > 0) && <>
              <Container fluid>
                {/* <div className="mb-4 pb-1 border-bottom">
                  <h1 className="mb-3">Beneficial Ownership Information Report (BOIR)</h1>
                  <p className="fw-500">OMB No. 1506-0076</p>
                  <p className="fw-500">Reporting companies, however, must include all information 31 CFR 1010.380(b) requires them to provide, regardless of whether a field is marked with an asterisk (<span className="txt-red">*</span>) symbol.</p>
                </div> */}
                <div className='page-title'>
                  <h5>Prepare BOIR</h5>
                  <span className='step-title'>
                    {steps[step-1].icon && <img src={steps[step-1].icon} className="me-1" />}
                    <span className="fw-500">{steps[step-1].label}</span>
                  </span>
                </div>
                {/* {step}, {curPageName} */}
                <div className='steps-progress'>
                  {steps.map((item, index) => {
                    let curClass = '';
                    if(step-1 === index){
                      curClass = 'active'
                    } else if(index < step){
                      curClass = 'completed'
                    }
                    return(<><div key={index} className={curClass + ' step-point'}><span></span></div>{index < steps.length-1 && <div className="step-connector" style={{width: `calc((100% / ${steps.length-1}) - 33px)`}}></div>}</>)
                  })}
                </div>
              </Container>

              <FormSubmit prevStep={prevStep} nextStep={nextStep} token={token} curPageName={curPageName} stepNum={step}stepLength={steps.length} handleLogout={handleLogout} email_id={emailid} setPageContent={setPageContent} applicationId={applicationId} customerGroupGet={customerGroupGet} />
            </>}
          </main>
        </>}
      </>}
    </div>
  );
}

export default Report;