import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import UserService from "../../services/UserService";
import Header from "../../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const serverConfig = require("../../config/server.js");

const RegisterUser = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({ password: "", confirmPassword: "" });
  const [tokenStatus, setTokenStatus] = useState("");
  const ref_id = searchParams.get("refid");

  const navigate = useNavigate();

  const checkValidity = () => {
    axios
      .post(serverConfig.api_base_url + "user_signup_validate", {
        ref_token: ref_id,
      })
      .then((response) => {
        if (response.data.status === 502) {
          setTokenStatus("Invalid");
        } else if (response.data.status === 200) {
          setTokenStatus("Valid");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);

    // Password validation checks
    const hasUpperCase = /[A-Z]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const isValidLength = value.length >= 8;

    if (!isValidLength) {
        setErrors((prev) => ({
            ...prev,
            password: "Password must be at least 8 characters",
        }));
    } else if (!hasUpperCase) {
        setErrors((prev) => ({
            ...prev,
            password: "Password must contain at least one uppercase letter",
        }));
    } else if (!hasSpecialChar) {
        setErrors((prev) => ({
            ...prev,
            password: "Password must contain at least one special character",
        }));
    } else {
        setErrors((prev) => ({
            ...prev,
            password: "",
        }));
    }

    // Confirm password check
    if (confirmPassword && value !== confirmPassword) {
        setErrors((prev) => ({
            ...prev,
            confirmPassword: "Passwords do not match",
        }));
    } else {
        setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
};

const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    if (password !== value) {
        setErrors((prev) => ({
            ...prev,
            confirmPassword: "Passwords do not match",
        }));
    } else {
        setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
};


  const validatePassword = () => {
    if (password === "") {
      setErrors((prev) => ({ ...prev, password: "Password is required" }));
      return false;
    }
    setErrors((prev) => ({ ...prev, password: "" }));
    return true;
  };

  const userSignUpComplete = () => {
    if (validatePassword() && errors.confirmPassword === "") {
      axios
        .post(serverConfig.api_base_url + "user_signup_complete", {
          password: password,
          ref_token: ref_id,
        })
        .then((response) => {
          if (response.data.status === 200) {
             navigate("/customer");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    checkValidity();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match" });
      return;
    }
    setErrors({ confirmPassword: "" });
    userSignUpComplete();
  };

  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="login-left">
            <img src={require("../../assets/images/login.jpg")} alt="Logo" />
          </div>
          <div className="form-container">
            <div className="signup-form-wrapper">
              {tokenStatus === "Invalid" ? (
                <p className="error-display">Invalid token</p>
              ) : (
                <></>
              )}
              <div className="kc-content">
                <div className="kc-content-wrapper">
                  <div className="kc-form">
                    <div className="kc-form-image">
                      <img
                        src={require("../../assets/images/logo.png")}
                        alt="Logo"
                      />
                    </div>
                    <Form onSubmit={handleSubmit}>
                      <div className="flex flex-col">
                        <Form.Group controlId="formPassword">
                          <Form.Control
                            className="signup-password"
                            type="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={handlePasswordChange}
                            isInvalid={
                              !!errors.password || !!errors.confirmPassword
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password || errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="flex flex-col mt-3">
                        <Form.Group controlId="formConfirmPassword">
                          <Form.Control
                            className="signup-password"
                            type="password"
                            placeholder="Confirm your password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            isInvalid={!!errors.confirmPassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="kc-form-buttons mt-4 mb-4">
                        <Button
                          variant="primary"
                          className="signup-submit-btn"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="disclaimer">
              This computer network belongs to Amberoon Inc. d/b/a Manatoko BOIR
              and may be used only by Amberoon employees and Customers only for
              work-related purposes. Amberoon Inc reserves the right to monitor
              use of this network to ensure network security and to respond to
              specific allegations of user misuse. Use of this network shall
              constitute consent to monitoring for such purposes. In addition,
              the Amberoon Inc reserves the right to consent to a valid law
              enforcement request to search the network for evidence of a crime
              stored within the network.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterUser;
